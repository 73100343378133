// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--2AhuS";
export var footerContainer = "footer-module--footer-container--3Ih25";
export var footerLogo = "footer-module--footer-logo--1yzBg";
export var siteLogo = "footer-module--site-logo--3lw8p";
export var siteFont = "footer-module--site-font--1HLcn";
export var nav = "footer-module--nav--3RzyD";
export var footerNavItem = "footer-module--footer-nav-item--1CktT";
export var footerNavItemLink = "footer-module--footer-nav-item-link--1U_XJ";
export var footerCopyRight = "footer-module--footer-copy-right--DpHj7";
export var footerCopyRightLink = "footer-module--footer-copy-right-link--32d7W";